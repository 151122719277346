const __request = require(`yinzhilv-js-sdk/frontend/common-project/lib/data-service/default/__request/__request_contentType_json`);
const redirect_to_login_page = require(`@/lib/common-service/redirect_to_login_page`).default;
const __request_contentType_json = function (pParameter) {
  if (!pParameter) pParameter = {};

  pParameter.appKey = `flight-search`;
  pParameter.redirect_to_login_page = redirect_to_login_page;
  return __request(pParameter);
};
module.exports = __request_contentType_json;
